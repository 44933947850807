// global.jQuery = require("jquery");
// const $ = global.jQuery;

// require('bootstrap');
require('lightslider');
require('spectragram');



$(function(){

 $('#lightSlider').lightSlider({
     gallery: true,
     item: 1,
     loop:false,
     slideMargin: 0,
     thumbItem: 10
 });

//https://github.com/adrianengine/jquery-spectragram/wiki/How-to-get-Instagram-API-access-token-and-fix-your-broken-feed
//https://www.instagram.com/oauth/authorize/?client_id=cc701edfb52949c79025762bb671566b&redirect_uri=http://localhost:3000&response_type=token&scope=public_content

var Spectra = {
  instaToken: '4161931755.cc701ed.dc24c07405864450bc39ee7b14f12883',
  instaID: 'cc701edfb52949c79025762bb671566b',
  
  init: function () {
    $.fn.spectragram.accessData = {
      accessToken: this.instaToken,
      clientID: this.instaID
    };
    
    $('#photo-container').spectragram('getUserFeed',{
      max: 4,
      query: '100sails',
      wrapEachWith: '<div class="photo">',
      size: 'big'
    });
  }
}

Spectra.init();

  var $hamburgerButton = $('.header__hamburger')
  var $main = $('.main')

  $hamburgerButton.on('click', function(){
    $main.toggleClass('open')
  })

  $('.hamburger-basement').find('a').on('click', function(){
    $main.removeClass('open')
  })

  var objectFitImages = require('object-fit-images');
  objectFitImages();

  $('.gallery .square').on('click', function(e){
    $('.gallery').toggleClass('full');
    $(this).toggleClass('main');
  })

})